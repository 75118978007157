// Use HTTPS for both endpoints
let CONTIGO_API_URL = process.env.NODE_ENV === 'production' 
  ? (process.env.REACT_APP_API_URL || 'https://api.contigou.com/') 
  : process.env.REACT_APP_API_URL;

// Force HTTPS in production, even if somehow the environment variable has HTTP
if (process.env.NODE_ENV === 'production' && CONTIGO_API_URL && CONTIGO_API_URL.startsWith('http:')) {
  CONTIGO_API_URL = CONTIGO_API_URL.replace('http:', 'https:');
}

// Ensure we're not using localhost in production
if (process.env.NODE_ENV === 'production' && CONTIGO_API_URL && CONTIGO_API_URL.includes('localhost')) {
  // Replace with the correct production domain
  CONTIGO_API_URL = 'https://api.contigou.com/';
}

export { CONTIGO_API_URL };

export const CONTIGO_WEB_URL = "https://contigou.com/"; 